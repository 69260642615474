import React, { useEffect, useState } from "react";
import {
  FaReact,
  FaPython,
  FaAws,
  FaGithub,
  FaBootstrap,
} from "react-icons/fa";
import { IoLogoJavascript, IoLogoVercel } from "react-icons/io5";
import { SiFlask, SiMysql } from "react-icons/si";
import { BiLogoPostgresql } from "react-icons/bi";
import { RiTailwindCssFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import userImg from "../../assets/me.9abdd6f317ae8d25716a.png";
import "./About.css";

function About() {
  const [showAbout, setShowAbout] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setShowAbout(true);
    }, 100);
  }, []);

  const navigateTo = (route) => {
    setTimeout(() => {
      navigate(`/${route}`);
    }, 100);
  };

  const handleEmailContact = () => {
    window.open("mailto:hernandezchristian94@gmail.com");
  };

  return (
    <div className="About">
      <div id="cssportal-grid">
        <div
          id="div1"
          style={{
            left: showAbout ? "0" : "-10px",
            opacity: showAbout ? "1" : "0",
          }}
        >
          <h3>Who am I?</h3>
          <p>
            Hi, I’m Christian Hernandez, a web developer who loves creating
            clean, functional websites. With a background in software
            engineering, I focus on building user-friendly, responsive designs
            and solving coding challenges. Let’s connect and build something
            great together!
          </p>
        </div>
        <div
          id="div2"
          onClick={() => navigateTo("projects")}
          style={{
            top: showAbout ? "0" : "-10px",
            opacity: showAbout ? "1" : "0",
          }}
        >
          PROJECTS
        </div>
        <div
          id="div3"
          style={{
            bottom: showAbout ? "0" : "-10px",
            opacity: showAbout ? "1" : "0",
          }}
        >
          <h5>Work experience.</h5>
          <div className="work">
            <div className="title">
              <span>
                Software Engineer <br /> Quirk & Quill Studios
              </span>
              <span>
                02/2023 - Present <br /> Loma Linda
              </span>
            </div>
            <ul>
              <li>
                <b>Developed dynamic</b> front-end components with React.js,
                enhancing the management, manipulation, and display of data.
              </li>
              <li>
                <b>Utilized JavaScript</b> to implement asynchronous programming
                techniques and optimize performance.
              </li>
              <li>
                <b>Led the transition of legacy systems</b> to a cloud-based
                infrastructure, improving system reliability and reducing
                operational costs.
              </li>
            </ul>
          </div>
        </div>
        <div
          id="div4"
          style={{
            opacity: showAbout ? "1" : "0",
          }}
          onClick={() => navigateTo("")}
        >
          <img src={userImg} width={75} />
          <h4>Christian Hernandez</h4>
        </div>
        <div
          id="div5"
          style={{
            right: showAbout ? "0" : "-10px",
            opacity: showAbout ? "1" : "0",
          }}
        >
          <h5>Technologies I've worked with.</h5>
          <ul className="technologies">
            <li>
              <FaReact size={24} />
            </li>
            <li>
              <IoLogoJavascript size={24} />
            </li>
            <li>
              <FaPython size={24} />
            </li>
            <li>
              <SiFlask size={24} />
            </li>
            <li>
              <IoLogoVercel size={24} />
            </li>
            <li>
              <FaAws size={24} />
            </li>
            <li>
              <FaGithub size={24} />
            </li>
            <li>
              <SiMysql size={24} />
            </li>
            <li>
              <BiLogoPostgresql size={24} />
            </li>
            <li>
              <RiTailwindCssFill size={24} />
            </li>
            <li>
              <FaBootstrap size={24} />
            </li>
          </ul>
        </div>
        <div
          id="div6"
          style={{
            right: showAbout ? "0" : "-10px",
            opacity: showAbout ? "1" : "0",
          }}
          onClick={handleEmailContact}
        >
          CONTACT
        </div>
      </div>
    </div>
  );
}

export default About;
