import { useState } from "react";
import Home from "./routes/Home/Home.js";
import About from "./routes/About/About.js";
import Projects from "./routes/Projects/Projects.js";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

function App() {
  const [show, setShow] = useState(false);

  return (
    <Router>
      <div className="App">
        <div className="app-wrapper">
          <Routes>
            <Route path="/" element={<Home show={show} setShow={setShow} />} />
            <Route
              path="/about"
              element={<About show={show} setShow={setShow} />}
            />
            <Route
              path="/projects"
              element={<Projects show={show} setShow={setShow} />}
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
