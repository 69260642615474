import React, { useEffect, useState } from "react";
import profileImg from "../../assets/me.9abdd6f317ae8d25716a.png";
import { BsCursorFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "./Home.css";
function Home({ show, setShow }) {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  const navigateTo = (route) => {
    setShow(false);
    setTimeout(() => {
      navigate(`/${route}`);
    }, 100);
  };

  const handleEmailContact = () => {
    window.open("mailto:hernandezchristian94@gmail.com");
  };

  console.log(show);

  return (
    <div className="Home">
      <div className="home-top">
        <div
          className="img-name"
          style={{ left: show ? "0" : "-10px", opacity: show ? "1" : "0" }}
        >
          <img src={profileImg} alt="Profile" width={60} height={60} />
          <p>
            Hello, I'm <br /> Christian Hernandez
          </p>
        </div>
        <h1
          className="sage-green"
          style={{ left: show ? "0" : "-10px", opacity: show ? "1" : "0" }}
        >
          FULL-STACK
        </h1>
      </div>
      <div className="home-bottom">
        <h1
          className="beige"
          style={{ bottom: show ? "0" : "-10px", opacity: show ? "1" : "0" }}
        >
          SOFTWARE
        </h1>
        <div className="mosaic">
          <h1
            className="rose"
            style={{ right: show ? "0" : "-10px", opacity: show ? "1" : "0" }}
          >
            DESIGNER
            <span className="location">
              // Based in <br /> Southern California
            </span>
          </h1>
          <h1
            className="cream"
            style={{ bottom: show ? "0" : "-10px", opacity: show ? "1" : "0" }}
          >
            & DEVELOPER
          </h1>
          <p style={{ right: show ? "0" : "-10px", opacity: show ? "1" : "0" }}>
            A web developer looking to create amazing art for the web.
          </p>
          <button
            onClick={handleEmailContact}
            style={{
              bottom: show ? "0" : "-10px",
              opacity: show ? "1" : "0",
              cursor: "pointer",
            }}
          >
            <span className="dot"></span> Let's Connect
          </button>
        </div>
      </div>
      <div style={{ opacity: show ? "1" : "0" }} className="cursor-abouut">
        <BsCursorFill />
        <span
          onClick={() => {
            navigateTo("about");
          }}
        >
          WHO AM I?
        </span>
      </div>
      <div style={{ opacity: show ? "1" : "0" }} className="cursor-projects">
        <BsCursorFill />
        <span
          onClick={() => {
            navigateTo("projects");
          }}
        >
          PROJECTS
        </span>
      </div>
    </div>
  );
}

export default Home;
