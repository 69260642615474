import React, { useState, useEffect } from "react";
import leftKoast from "../../assets/LEFTKOAST-LOGO.png";
import waybad from "../../assets/WAYBAD-LOGO.png";
import BBP from "../../assets/BBP-LOGO.png";
import { IoReturnUpBackOutline } from "react-icons/io5";
// import SBCuts from "../../assets/SBCUTS-LOGO.png";
import "./Projects.css";
import { Link, useNavigate } from "react-router-dom";

function Projects() {
  const [showProjects, setShowProjects] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setShowProjects(true);
    }, 100);
  }, []);

  const navigateTo = (route) => {
    setTimeout(() => {
      navigate(`/${route}`);
    }, 100);
  };
  const projects = [
    {
      year: "2024",
      name: "Left Koast",
      description:
        "Left Koast Productions is an e-commerce and streaming platform for books, allowing users to register as either readers or authors. Authors can submit their books, expand the catalog, and earn profits from each sale. The site is built using React, Flask, Vercel, and Stripe.",
      url: "https://leftkoast.com/",
      img: leftKoast,
      tags: "React.js, Flask, Vercel, Stripe, PostgreSQL",
    },
    {
      year: "2024",
      name: "Waybad",
      description:
        "Waybad is a clothing brand company built on WordPress with WooCommerce and Stripe. It offers a seamless shopping experience for customers, integrating robust e-commerce functionality with secure payment processing.",
      url: "https://waybadofficial.com/",
      img: waybad,
      tags: "WordPress, Elementor, Stripe, WooCommerce",
    },
    {
      year: "2023",
      name: "Big Bear Performance",
      description:
        "Big Bear Performance offers high-performance shocks and suspensions for Harley-Davidson motorcycles, enhancing riding experiences with top-quality parts.",
      url: "https://bigbearperformance.com/",
      img: BBP,
      tags: "WordPress, ShipStation, WooCommerce, Elementor, AWS, Lightsail",
    },
    {
      year: "2022",
      name: "San Bernardino Cuts",
      description:
        "San Bernardino Cuts is a cosmetology and barbering apprenticeship school based in San Bernardino. Powered by React, Flask, and Vercel, it provides a modern platform for training and career development in the beauty industry.",
      url: "https://sbcapprenticeship.com/",
      //   img: SBCuts,
      tags: "React.js, Flask, Vercel",
    },
  ];

  return (
    <div className="Projects">
      <div className="projects-header">
        <h3>Projects</h3>
        <Link onClick={() => navigateTo("")}>
          <IoReturnUpBackOutline size={24} />
        </Link>
      </div>
      <ul className="projects-list">
        {projects.map((project, index) => (
          <li
            style={{
              backgroundImage: project.img ? `url(${project.img})` : "none",
              left: showProjects ? "0" : "-10px",
              opacity: showProjects ? "1" : "0",
            }}
            className="project-card"
            key={project.name}
          >
            <div className="project-wrapper">
              <div className="project-info">
                <h5>{project.year}</h5>
                <h4>{project.name}</h4>
                <p>{project.description}</p>
              </div>
              <a href={project.url} target="_blank" rel="noreferrer">
                Visit
              </a>
              <ul>
                {project.tags.split(",").map((tag) => (
                  <li key={tag.trim()}>{tag.trim()}</li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Projects;
